import './symptoms.scss';
import React, { useState, useEffect, FC } from 'react';
import 'twin.macro';
import tw, { css, styled } from 'twin.macro';

type UnderstandingSymptomsParams = {
  //
};

const UnderstandingSymptoms: FC<UnderstandingSymptomsParams> = () => {
  return (
    <section
      className="subsection understanding__subsection"
      data-id="symptoms"
      aria-label="Symptoms of UTUC"
    >
      <h3 className="subsection__title">
        Symptoms of UTUC may overlap with symptoms of bladder cancer, but some
        are unique
      </h3>
      <div className="subsection__content_section">
        <p>
          The most common symptom of UTUC is blood in the urine. Keep in mind,
          though, that some people with UTUC have no symptoms at all.
        </p>
        <SymptomTable tw="my-8">
          <TableRow>
            <TableHead tw="border-width[2px 1px 1px 2px] border-brand-blue-light">
              Symptoms related to
              <DeskBreaker /> bladder cancer
            </TableHead>
            <TableHead tw="border-width[2px 1px 1px 1px] border-brand-blue-light">
              Symptoms related to bladder
              <DeskBreaker /> cancer and UTUC
            </TableHead>
            <TableHead tw="border-width[2px 2px 1px 1px] border-brand-blue-light">
              Symptoms related to UTUC
            </TableHead>
          </TableRow>
          <TableRow>
            <TableData tw="border-left[none]!">Stomach pain</TableData>
            <TableData>
              Blood in the urine
              <DeskBreaker /> (hematuria)
            </TableData>
            <TableData
              rowSpan={2}
              tw="border-right[none] border-b-2 border-brand-blue-light md:whitespace-nowrap"
            >
              Flank pain (between the
              <DeskBreaker /> ribs and pelvis on either side <DeskBreaker />
              of the lower back)
            </TableData>
          </TableRow>
          <TableRow>
            <TableData tw="border-left[none] border-b-2 border-brand-blue-light">
              Lower back pain
            </TableData>
            <TableData tw="border-b-2 border-brand-blue-light">
              Increased, urgent,
              <DeskBreaker /> or painful urination
            </TableData>
          </TableRow>
        </SymptomTable>
      </div>
    </section>
  );
};

export default UnderstandingSymptoms;
const SymptomTable = tw.table`line-height[1.2] border-separate border-spacing[0]`;
const TableRow = tw.tr`color[#064B74] font-semibold text-center border[2px solid #95B9C7] border-radius[10px 10px 0 0]`;
const TableHead = tw.th`font-semibold text-center
  p-2 border[2px solid #95B9C7] border-radius[10px 10px 0 0]
  md:(py-4 px-6 font-size[22px])
  `;
const TableData = tw.td`text-sm p-2 border[1px solid #95B9C7] font-normal md:(py-4 px-6 text-xl)`;
const DeskBreaker = tw.br`hidden md:block`;
