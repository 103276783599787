import './causes.scss';

import React, { FC } from 'react';
import CausesItems from '../causesItems/causesItems';

const UnderstandingRisks: FC<{ onLoadinComplete?: () => {} }> = ({
  onLoadinComplete,
}) => {
  return (
    <section
      className="subsection understanding__subsection"
      data-id="causes"
      aria-label="causes of UTUC"
    >
      <h3 className="subsection__title">UTUC is a rare cancer</h3>
      <p>
        It’s hard to know exactly what causes UTUC, but there are several things
        that could increase your chances:
      </p>
      <CausesItems styleName="understanding__causes_items" />
      <div className="understanding__smoker_tout" style={{ marginTop: '45px' }}>
        <h3 className="subsection__title blue">Are you a smoker?</h3>
        <p className="color--blue-dark">
          If you smoke, quitting could lower your chances of getting more tumors
          in the future. For help quitting, don't be afraid to talk to your
          doctor.
        </p>
      </div>
    </section>
  );
};

export default UnderstandingRisks;
