import './understanding-utuc.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { navigate } from '@reach/router';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import cornerImage from '../images/page-header-corner-only.png';

import { gotoHash, snakeCase } from '../components/utils';
import UnderstandingStatistics from '../components/understanding/statistics';
import UnderstandingCauses from '../components/understanding/causes';
import UnderstandingSymptoms from '../components/understanding/symptoms';
import UnderstandingTreatment from '../components/understanding/types-of-utuc';
import Definition from '../components/definition/definition';
import { isSSG } from '../utils';

const Understanding = ({ location }) => {
  location = location ?? (isSSG ? null : window.location);

  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "people-03-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tablet: file(relativePath: { eq: "people-03-tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "people-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ];

  const navItemData = useMemo(
    () => [
      'UTUC stats & facts',
      'Why people get it',
      'Symptoms',
      'Types of UTUC',
    ],
    [],
  );

  const [activeSection, setActiveSection] = useState(
    `#${snakeCase(navItemData[0])}`,
  );

  const switchSection = section => {
    navigate(`#${section}`);
  };

  const ActiveSection = () => {
    let activeComponent;
    switch (activeSection) {
      case `#${snakeCase(navItemData[0])}`:
        activeComponent = <UnderstandingStatistics />;
        break;
      case `#${snakeCase(navItemData[1])}`:
        activeComponent = <UnderstandingCauses />;
        break;
      case `#${snakeCase(navItemData[2])}`:
        activeComponent = <UnderstandingSymptoms />;
        break;
      case `#${snakeCase(navItemData[3])}`:
        activeComponent = (
          <UnderstandingTreatment
            location={location}
            activeSection={activeSection}
          />
        );
        break;
      default:
        activeComponent = <UnderstandingStatistics />;
        break;
    }
    return activeComponent;
  };

  useEffect(() => {
    if (location.hash) {
      const hash = gotoHash(
        location.hash,
        navItemData,
        '.page-header__nav_list',
      ); //hash, known hashes, selector, smooth(optional)
      if (hash && activeSection !== hash) {
        setActiveSection(hash);
      }
    }
  }, [location.hash, activeSection, navItemData]);

  return (
    <Layout>
      <SEO
        title="UTUC | What You Should Know About Upper Tract Urothelial Cancer"
        keywords={[
          'UTUC',
          'upper tract urothelial cancer',
          'upper tract urothelial carcinoma',
          'renal pelvis cancer',
          'cancer in the lining of the kidney',
          'kidney cancer',
        ]}
        description="It&rsquo;s important to learn as much as you can about UTUC so that you can choose the right treatment for you. Read about UTUC statistics, why people get it, the risks, and more"
        page="understanding"
      />

      <div className="understanding__container">
        <BackgroundImage
          Tag="section"
          fluid={backgroundArtDirectionStack}
          className="understanding__header page-header"
          aria-label="Understanding header"
          critical={true}
          fadeIn={false}
          durationFadeIn={0}
        >
          <div className="understanding__header_content">
            <div>
              <h2 className="understanding__header_title page-header__title">
                What you should know about UTUC
              </h2>
              <div className="page-header__content">
                <p style={{ marginTop: '23px' }}>
                  Knowing as much as you can about UTUC will help you partner
                  with your doctor so you can make the treatment choice that’s
                  best for you.
                </p>
                <p style={{ marginTop: '23px' }}>
                  The first thing to know is that UTUC is sometimes confused
                  with <Definition term="bladder cancer" id="bladder_cancer" />{' '}
                  and <Definition term="kidney cancer" id="kidney_cancer" />.
                  But UTUC is actually a distinct condition that takes place in
                  the lining of the kidneys and the{' '}
                  <Definition term="ureter" id="ureter" displayAs="ureters" />.
                </p>
                <p style={{ marginTop: '23px' }}>
                  Click through the tabs below for other important things to
                  know about this rare disease.
                </p>
              </div>
            </div>
          </div>
          <div className="page-header__nav_container">
            <img
              className="page-header__nav_corner"
              src={cornerImage}
              alt="presentational corner radius for nav menu"
            />
            <nav
              className="page-header__nav understanding__page_nav"
              aria-label="understanding subpages"
            >
              <ul className="page-header__nav_list">
                {navItemData.map((navItem, index) => {
                  const slug = snakeCase(navItem);
                  return (
                    <li
                      key={`understandingNavItem${index}`}
                      className={`page-header__nav_item ${
                        '#' + slug === activeSection ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-header__nav_link"
                        onClick={e => {
                          e.preventDefault();
                          switchSection(slug);
                        }}
                      >
                        {navItem}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </BackgroundImage>
        <div className="page-content">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeSection}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade"
            >
              <ActiveSection />
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </Layout>
  );
};

export default Understanding;
