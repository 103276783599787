import './statistics.scss';
import React, { FC } from 'react';
import iconMale from '../../images/icon-male.svg';
import iconKidney from '../../images/kidney-system.svg';
import 'twin.macro';

const UnderstandingStatistics: FC<{ onLoadinComplete?: () => {} }> = ({
  onLoadinComplete,
}) => {
  return (
    <section
      className="subsection understanding__subsection"
      data-id="statistics"
      aria-label="Understanding UTUC"
    >
      <div className="utuc-statistics__bg_container" tw="hidden lg:block">
        <h3 className="subsection__title">UTUC is a rare cancer</h3>
        <p className="utuc-statistics__stat" data-id="1">
          <strong>5%</strong> to <strong>10%</strong> of all urinary tract
          cancer happens in the upper urinary tract
        </p>
        <p className="utuc-statistics__stat" data-id="2">
          <strong>~5000 people</strong> will be diagnosed with UTUC every year
          in the US
        </p>
        <div className="utuc-statistics__stat" data-id="3">
          <p>
            The other <strong>90%</strong> to <strong>95%</strong> of urinary
            tract cancer takes place in the lower tract in the bladder
          </p>
        </div>
        <div className="utuc-statistics__stat" data-id="4">
          <h3>Did you know?</h3>
          <p>
            The lining of the kidneys, ureters, and bladder is the same, so
            there are similarities between UTUC and bladder cancer but also key
            differences
          </p>
        </div>
        <div className="utuc-statistics__stat with-img" data-id="5">
          <img
            className="utuc-statistics__img"
            src={iconMale}
            alt="Icon of a Male"
          />
          <p>
            UTUC is usually seen more in men than in women. About{' '}
            <strong>75% of UTUC cases happen in men</strong>
          </p>
        </div>
      </div>
      {/* Stacked layout for < 1024px */}
      <div className="subsection" tw="flex flex-col px-4 gap-6 py-4 lg:hidden">
        <h3 className="subsection__title" tw="bg-none">
          UTUC is a rare cancer
        </h3>
        <p className="utuc-statistics__stat">
          <strong>5%</strong> to <strong>10%</strong> of all urinary tract
          cancer happens in the upper urinary tract
        </p>
        <div className="utuc-statistics__stat">
          <p>
            The other <strong>90%</strong> to <strong>95%</strong> of urinary
            tract cancer takes place in the lower tract in the bladder
          </p>
        </div>
        <div className="utuc-statistics__stat with-img">
          <img
            className="utuc-statistics__img"
            src={iconMale}
            alt="Icon of a Male"
          />
          <p>
            UTUC is usually seen more in men than in women. About{' '}
            <strong>75% of UTUC cases happen in men</strong>
          </p>
        </div>
        <img
          src={iconKidney}
          alt="Icon of a kidney system"
          tw="align-self[center] max-width[400px] width[75vw]"
        />
        <p className="utuc-statistics__stat">
          <strong>~5000 people</strong> will be diagnosed with UTUC every year
          in the US
        </p>
        <div
          className="utuc-statistics__stat"
          tw="text-brand-blue-darker bg-[#E9F0F3] p-4 rounded-2xl width[75vw]"
        >
          <h3 tw="text-2xl">Did you know?</h3>
          <p tw="text-base line-height[1.25em] py-2">
            The lining of the kidneys, ureters, and bladder is the same, so
            there are similarities between UTUC and bladder cancer but also key
            differences
          </p>
        </div>
      </div>
    </section>
  );
};

export default UnderstandingStatistics;
